import { initializeApp } from 'firebase/app';
import { collection, getFirestore, type CollectionReference } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import type {
  UserDocument,
  UserPermissionDocumentRow,
  UserManagementPermissionsRequestDoc,
} from '../../functions/src/facility-safety-environmental-types';

const app = initializeApp(
  {
    apiKey: 'AIzaSyBSi1PieSTmXtKM1mQa7LOks1_j2otE7yc',
    authDomain: 'fir-auth-87615.firebaseapp.com',
    projectId: 'fir-auth-87615',
    storageBucket: 'fir-auth-87615.firebasestorage.app',
    messagingSenderId: '68939314018',
    appId: '1:68939314018:web:aa4647b18de1ce0aea723b',
  },
  'demo-auth'
);

const db = getFirestore(app);
export const usersCol = collection(db, 'users') as CollectionReference<UserDocument, UserDocument>;
export const permissionsCol = collection(db, 'permissions') as CollectionReference<
  UserPermissionDocumentRow,
  UserPermissionDocumentRow
>;
export const permissionsRequestsCol = collection(db, 'permissionsRequests') as CollectionReference<
  UserManagementPermissionsRequestDoc,
  UserManagementPermissionsRequestDoc
>;
export const auth = getAuth(app);
