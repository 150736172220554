<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type {
  UserManagementPermissionsRequestDoc,
  UserManagementPermissionsRequestInput,
} from '../../../functions/src/facility-safety-environmental-types';
import type {
  FacilityForPermissions,
  PermissionSetsAvailable,
  UserPermissionsApprover,
} from '../../../functions/src/permissions-types';
import type { PermissionRequestDefaults } from '../types';

declare const $: typeof import('jquery');
type JQueryWithSelect2 = ReturnType<typeof $> & {
  select2(args: { theme: 'bootstrap'; data: { id: string | number; text: string }[] }): JQueryWithSelect2;
};

export default defineComponent({
  props: {
    facilities: {
      type: Array as PropType<FacilityForPermissions[]>,
      required: true,
    },
    groups: {
      type: Array as PropType<string[]>,
      required: true,
    },
    permissionSetsAvailable: {
      type: Object as PropType<PermissionSetsAvailable>,
      required: true,
    },
    firebaseToken: {
      type: String,
      required: true,
    },
    facilityInitial: {
      type: String,
      required: false,
    },
    defaults: {
      type: Object as PropType<PermissionRequestDefaults>,
      required: true,
    },
    adminIsModifying: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['userPermissionsRequested'],

  data() {
    const result = {
      firstName: this.defaults?.firstName || '',
      lastName: this.defaults?.lastName || '',
      facilityInitialSet: false,
      facility: null as FacilityForPermissions | null,
      group: this.defaults?.group || null,
      userType: this.defaults?.type || 'auditor',
      userRole: this.defaults?.role || 'other',
      organizationalLocation: this.defaults?.organizationalLocation || '',
      reason: '',
      reasonAdditional: '',
      reasonShow: this.defaults?.reasonShow || false,
      possibleEmails: this.defaults?.possibleEmails || [],
      emailUsing: this.defaults?.emailUsing || '',
      duplicateFound: false,
      duplicateTimeout: null,
      requesting: false,
      success: false,

      approver: '',
      approvers: [] as UserPermissionsApprover[],
      approversLoading: false,
      approversShowing: false,
    };

    if (this.defaults?.facility) {
      const facility = this.facilities.find((f) => f.facility === this.defaults?.facility);
      if (facility) {
        result.facility = facility;
      }
    }

    return result;
  },

  computed: {
    addUserEnabled(): boolean {
      if (this.duplicateFound || this.requesting) {
        return false;
      }

      if (!this.firstName.trim() || !this.lastName.trim()) {
        return false;
      }

      if (
        this.userType === 'facilityAdmin' &&
        (this.facility === null || !this.permissionSetsAvailable.facilityAdmin.includes(this.facility.facility))
      ) {
        return false;
      }

      if (
        this.userType === 'groupAdmin' &&
        (this.group === null || !this.permissionSetsAvailable.groupAdmin.includes(this.group))
      ) {
        return false;
      }

      if (this.userType === 'auditor' && (this.group === null || this.facility === null)) {
        return false;
      }

      if (this.reasonShow) {
        if (!this.reason) {
          return false;
        }

        if (this.reason === 'Some other issue (please specify).' && !this.reasonAdditional) {
          return false;
        }
      }

      return true;
    },

    facilitiesFiltered(): FacilityForPermissions[] {
      if (this.group === null) {
        return this.facilities;
      }

      return this.facilities.filter((facility) => facility.group === this.group);
    },

    select2Data(): { id: string | number; text: string }[] {
      return [{ id: -1 as string | number, text: '-' }].concat(
        this.facilitiesFiltered.map((facility) => {
          return {
            id: facility.facility,
            text: facility.facility,
          };
        })
      );
    },
  },

  watch: {
    facilities: {
      handler() {
        if (this.$refs.facilitySelect) {
          const select2Data = this.select2Data;
          const $facilitySelect = $(this.$refs.facilitySelect) as JQueryWithSelect2;
          $facilitySelect.html('');
          $facilitySelect.select2({ theme: 'bootstrap', data: select2Data });
          this.facilityInitialHandle();
        }
      },
    },

    facilityInitial: {
      handler(newVal) {
        this.facilityInitialSet = !newVal;
        this.facilityInitialHandle();
      },
    },

    group: {
      handler(newVal) {
        if (this.$refs.facilitySelect) {
          const $facilitySelect = $(this.$refs.facilitySelect) as JQueryWithSelect2;
          const selected = $(this.$refs.facilitySelect).val() as string;
          const select2Data = this.select2Data;

          $facilitySelect.html('');
          $facilitySelect.select2({ theme: 'bootstrap', data: select2Data });
          if (newVal !== null && select2Data.find((i) => i.id === selected)) {
            $facilitySelect.val(selected);
          } else {
            $facilitySelect.val(-1);
          }
          $facilitySelect.trigger('change');
        }

        if (newVal === null && (this.userType === 'facilityAdmin' || this.userType === 'groupAdmin')) {
          this.userType = 'auditor';
        }

        this.approversFetch();
      },
    },

    facility: {
      handler(newVal) {
        if (newVal === null && this.userType === 'facilityAdmin') {
          this.userType = 'auditor';
        }

        this.approversFetch();
      },
    },

    userType() {
      this.approversFetch();
    },

    userRole() {
      this.approversFetch();
    },
  },

  methods: {
    async approversFetch(): Promise<void> {
      this.approver = '';

      const { group, facility, userType, userRole } = this;
      if (!group && !facility) {
        this.approvers = [];
        return;
      }

      this.approversLoading = true;

      const response = (await fetch('/auth/approvers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          facilityName: facility?.facility ?? '',
          group,
          type: userType,
          role: userRole,
        }),
      }).then((r) => r.json())) as
        | { status: 'error'; error: string }
        | { status: 'success'; approvers: UserPermissionsApprover[] };

      if (response.status === 'error') {
        this.approversLoading = false;
        console.error(response);
        return;
      }

      // Selections have changed before the backend finished, bail out:
      if (
        this.group !== group ||
        this.facility !== facility ||
        this.userType !== userType ||
        this.userRole !== userRole
      ) {
        return;
      }

      this.approversLoading = false;
      this.approvers = response.approvers;
      if (this.approvers.length > 0) {
        this.approversShowing = true;
        this.approver = response.approvers[0].email;
      }
    },

    userPermissionsRequest() {
      if (!this.addUserEnabled) {
        return;
      }

      this.requesting = true;

      let organizationalLocation = `/`;
      for (const part of ['users', 'demo', this.group ? this.group : '', this.facility ? this.facility.facility : '']) {
        if (part === '') {
          break;
        }

        organizationalLocation += part + '/';
      }

      const request: UserManagementPermissionsRequestInput = {
        firstName: this.firstName,
        lastName: this.lastName,
        organizationalLocation,
        facility: this.facility ? this.facility.facility : '',
        group: this.group || '',
        type: this.userType,
        role: this.userRole,
        reason: this.reason + (this.reasonAdditional ? `\n\n${this.reasonAdditional}` : ''),
        approver: this.approver,
      };

      this.success = true;
      this.$emit('userPermissionsRequested', request);
    },

    facilityInitialHandle() {
      if (this.facilityInitialSet || !this.facilityInitial) {
        return;
      }

      const select2Data = this.select2Data;
      const $facilitySelect = $(this.$refs.facilitySelect as HTMLSelectElement);

      if (select2Data.length > 1) {
        this.facilityInitialSet = true;
        if (select2Data.find((i) => i.id === this.facilityInitial)) {
          $facilitySelect.val(this.facilityInitial);
          $facilitySelect.trigger('change');
        }
      }
    },

    tooltipGet(type: NonNullable<UserManagementPermissionsRequestDoc['type']>): string {
      const tooltips = {
        auditor:
          'You are requesting to participate in audits, view audit files, and upload audit files for audits in your facility.',
        facilityAdmin:
          'You are requesting to be able to plan audits, update audits, view audits, upload files to audits, and manage users in your facility.',
        groupAdmin:
          'You are requesting to be able to plan audits, update audits, view audits, upload files to audits, and manage users in your group.',
        companyAdmin:
          'You are requesting to be able to plan audits, update audits, view audits, upload files to audits, and manage users across the whole company.',
        superAdmin: 'You are requesting to have full access to everything in the system (audits, users, etc.)',
      };

      let tooltip = tooltips[type];
      if (!tooltip) {
        return '';
      }

      if (this.userRole === 'bolcc') {
        tooltip =
          'You are requesting to access and manage the Bulk Oil and Liquid Chemical Containment (BOLCC) data associated with your facility.';
      } else if (this.userRole === 'limitsRead') {
        tooltip = 'You are requesting to view (but not update) limits associated with your facility.';
      } else if (this.userRole === 'limitsWrite') {
        tooltip = 'You are requesting to view and update limits associated with your facility.';
      }

      if ((type === 'facilityAdmin' || type === 'auditor') && this.facility) {
        tooltip = tooltip.replace('your facility', 'the ' + this.facility.facility + ' facility');
      } else if (type === 'groupAdmin' && this.group) {
        tooltip = tooltip.replace(/your (facility|group)/, 'the ' + this.group + ' group');
      } else if (type === 'companyAdmin') {
        tooltip = tooltip.replace(/your (facility|group)/, 'the whole Company');
      } else if (type === 'superAdmin') {
        tooltip = tooltip.replace(/your (facility|group)/, 'the whole Environmental Portals system');
      }

      if (this.adminIsModifying) {
        tooltip = tooltip.replace(/your/g, 'their').replace(/You/g, 'They');
      }

      return tooltip;
    },
  },

  mounted() {
    if (/vendor/.test(window.location.origin)) {
      this.userRole = 'vendor';
    }

    const $facilitySelect = $(this.$refs.facilitySelect as HTMLSelectElement) as JQueryWithSelect2;
    $facilitySelect.select2({ theme: 'bootstrap', data: this.select2Data });
    $facilitySelect.on('change', () => {
      if ((this.$refs.facilitySelect as HTMLSelectElement).selectedIndex === 0) {
        this.facility = null;
      } else {
        this.facility = this.facilitiesFiltered[(this.$refs.facilitySelect as HTMLSelectElement).selectedIndex - 1];
        this.group = this.facility.group;
      }
    });

    this.facilityInitialHandle();
  },
});
</script>

<template>
  <form @submit.prevent="userPermissionsRequest()">
    <div class="form-group">
      <label for="user-permissions-request-first-name" class="control-label">First Name:</label>
      <input type="text" v-model="firstName" id="user-permissions-request-first-name" class="form-control" />
    </div>

    <div class="form-group">
      <label for="user-permissions-request-last-name" class="control-label">Last Name: </label>
      <input type="text" v-model="lastName" id="user-permissions-request-last-name" class="form-control" />
    </div>

    <div class="form-group">
      <label for="user-permissions-request-group" class="control-label">Group: </label>
      <select v-model="group" id="user-permissions-request-group" class="form-control">
        <option :value="null">-</option>
        <option v-for="group in groups" :value="group">
          {{ group }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label for="user-permissions-request-facility" class="control-label">Facility: </label>
      <select ref="facilitySelect" id="user-permissions-request-facility" class="form-control"></select>
    </div>

    <div class="form-group">
      <label for="user-permissions-request-user-role">Role: </label>
      <select v-model="userRole" id="user-permissions-request-user-role" class="form-control">
        <option value="safety">Safety</option>
        <option value="environmental">Environmental</option>
        <option value="vendor">Vendor Audits</option>
        <option value="safety-and-environmental">Safety and Environmental</option>
        <option value="bolcc">BOLCC-only</option>
        <option value="limitsRead">Limits Viewer</option>
        <option value="limitsWrite">Limits Viewer and Updater</option>
        <option value="other">Other</option>
      </select>
      <p class="help-block" v-if="userRole === 'other'">
        If you're regularly involved with Safety, Environmental, or Vendor audits, please indicate this above.
      </p>
    </div>

    <div class="form-group" v-if="userRole !== 'limitsRead' && userRole !== 'limitsWrite'">
      <label for="user-permissions-request-user-type" class="control-lab">User Type: </label>
      <select v-model="userType" id="user-permissions-request-user-type" class="form-control">
        <option value="auditor" :title="tooltipGet('auditor')" v-if="permissionSetsAvailable.auditor">
          General User
        </option>
        <option
          value="facilityAdmin"
          :title="tooltipGet('facilityAdmin')"
          v-if="facility && facility.facility && permissionSetsAvailable.facilityAdmin.includes(facility.facility)"
        >
          {{ facility ? facility.facility : 'Facility' }} Admin
        </option>
        <option
          value="groupAdmin"
          :title="tooltipGet('groupAdmin')"
          v-if="group && permissionSetsAvailable.groupAdmin.includes(group)"
        >
          {{ group ? group : 'Group' }} Admin
        </option>
        <option value="companyAdmin" :title="tooltipGet('companyAdmin')" v-if="permissionSetsAvailable.companyAdmin">
          Company Admin
        </option>
        <option value="superAdmin" :title="tooltipGet('superAdmin')" v-if="permissionSetsAvailable.superAdmin">
          Super Admin
        </option>
      </select>
    </div>

    <p class="help-block">{{ tooltipGet(userType) }}</p>

    <template v-if="approversShowing">
      <div class="form-group">
        <label for="user-permissions-request-approver" class="control-label">
          Who should review and approve your account?:
        </label>
        <select
          v-model="approver"
          id="user-permissions-request-approver"
          class="form-control"
          :disabled="approvers.length === 0"
        >
          <option value="">{{ approversLoading ? 'Loading...' : 'Admins' }}</option>
          <option v-for="approver of approvers" :key="approver.email" :value="approver.email">
            {{ approver.name }} ({{ approver.type === 'facilityAdmin' ? 'Facility Admin' : 'Group Admin' }})
          </option>
        </select>
      </div>
      <p class="help-block" v-if="approvers.length > 0">
        If you are in contact with a facility or group admin, select them above to ensure quick approval of your
        account.
      </p>
    </template>

    <div class="form-group" v-if="reasonShow">
      <label for="user-permissions-request-reason"> Please explain the issue with your existing account: </label>
      <select v-model="reason" class="form-control">
        <option>
          I no longer have access to
          {{ possibleEmails.join(', ') || 'the other account' }}.
        </option>
        <option>I prefer to use {{ emailUsing || 'this account' }}.</option>
        <option>Some other issue (please specify).</option>
      </select>
      <textarea
        v-if="reason === 'Some other issue (please specify).'"
        id="user-permissions-request-reason"
        class="form-control"
        rows="5"
        style="width: 100%"
        placeholder="Describe issue here..."
        v-model="reasonAdditional"
      ></textarea>
    </div>

    <button type="submit" class="btn btn-primary" style="width: 100%" :disabled="!addUserEnabled" v-if="!success">
      <template v-if="adminIsModifying">
        <template v-if="!requesting">Add Permissions</template>
        <template v-if="requesting"><span class="glyphicon glyphicon-refresh"></span> Adding...</template>
      </template>
      <template v-if="!adminIsModifying">
        <template v-if="!requesting">Request Access</template>
        <template v-if="requesting"><span class="glyphicon glyphicon-refresh"></span> Requesting access...</template>
      </template>
    </button>

    <div class="alert alert-success" v-if="success">
      Your request has been submitted! An admin will process your request as soon as they can.
    </div>
  </form>
</template>
